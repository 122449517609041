.canvas-container {
    border: 1px solid;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 420px;
}

.canvas-image {
    max-width: 420px !important;
    max-height: 450px !important;
    position: relative;
}

.loading-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
}