.login-container, .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #a200ff;
    font-size: 14px;
  }
  
  .login-card {
    background-color: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 40px;
    text-align: center;
    max-width: 320px;
    width: 100%;
  }

  .login-card .logo-image {
    width: 120px;
    margin: auto;
    margin-bottom: 18px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-container input, .signup-container input {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  .login-container input:focus-visible, .signup-container input:focus-visible {
    outline: #a200ff auto 1px;
  }
  
  .login-btn, .signup-btn {
    width: 100%;
    background-color: #a200ff;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin: 6px 0;
    padding: 6px 10px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .login-btn, .signup-btn:hover {
    background-color: #9900f1;
  }
  
  .register-link {
    margin-top: 10px;
  }
  
  .register-link a {
    color: #a200ff;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .login-card {
      width: 80%;
    }
  }
  
  @media (max-width: 576px) {
    .login-card {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 20px;
    }
  }
  