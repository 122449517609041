nav {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 1% 5% !important;
    z-index: 10000;
}

#nav-items {
    justify-content: flex-end !important;
}

.nav-link {
    font-size: 15px;
}

.dropdown-item {
    color: #404040;
    font-size: 15px;
}

.nav-link:hover {
    color: #a200ff;
    text-decoration: none;
}

.dropdown-menu[data-bs-popper] {
    right: 0;
    left: unset;
}

.credits {
    background: #f0f0f0;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 30px;
    margin-left: 10px;
}