/* ImageInput.css */

.image-upload-container {
    padding: 60px;
}

.image-upload-container .top {
    text-align: center;
    color: #0E0E0E;
    margin: 2rem 0;
}

.image-upload-container .top .title {
    font-size: 3rem;
    margin: 0;
}

.image-upload-container .top .desc {}

.image-upload-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cn-image-container {}

.cn-image-container .cn-img {
    display: flex;
    justify-content: center;
    margin: 18px 0;
    flex-direction: column;
    align-items: center;
}

.cn-image-container .cn-img label {
    display: flex;
    align-items: center;
    margin: 8px 0;
}
  

.cn-image-container .cn-img input[type="file"]::file-selector-button {
    background-color: #a200ff;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
    /* text-decoration: underline; */
}

.image-upload {
    width: 75vw;
    padding: 1px;
    background-color: #a200ff;
    border-radius: 10px;
    margin: auto;

}

.image-upload:hover {
    background-color: #9900f1;
}

.input-container {
    position: relative;
    padding: 20px;
}

.image-input-text {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    color: white;
    font-weight: 600;
    width: fit-content;
    margin: auto;
    line-height: 1.5;
    font-size: 15px;
}

.image-input-text p {
    margin: auto;
    width: fit-content;
}

.input-container input[type="file"] {
    padding: 12% 30%;
    border: 1px dashed white;
    background-color: #a200ff;
    border-radius: 10px;
    color: white;
    color: transparent;
    width: -moz-available;
    min-width: -webkit-fill-available;
}

.input-container input[type="file"]:hover {
    background-color: #9900f1;
    cursor: pointer;
}

.input-container input[type="file"]::file-selector-button {
    background-color: transparent;
    color: transparent;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: underline;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 30px 0;
}

.remove-bg-btn,
.download-btn {
    background-color: #a200ff;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 10px;
    transition: background-color 0.3s ease-in-out;
}

.remove-bg-btn:hover,
.download-btn:hover {
    background-color: #9900f1;
}

.upload-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 40px 60px;
    border: 1px dashed #ffffff;
    background-color: #a200ff;
    border-radius: 10px;
    transition: border 0.3s ease-in-out;
}

.upload-text p {
    margin: 2px;
    color: #fff;
    font-weight: 600;
}

.check label {
    color: #fff;
    cursor: pointer;
    background-color: #a200ff;
    font-weight: bold;
    display: block;
    text-align: center;
    text-decoration: underline;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 10px;
}


.preview-image {
    position: relative;
    /* background: lightblue;
    background-position: center;
    background-size: contain; */
}

.preview-not-selected {
    background: lightgray;
}

.delete-button {
    position: absolute;
    right: 0;
    top: 0;
    background: #5e5e5e;
    border-radius: 3rem;
    margin: 10px;
    color: #fff;
    padding: 2px 6px 3px;
}

.delete-button:hover {
    background: rgb(176, 176, 176);
}

.loader-bg-container {   
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0009;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


@media screen and (max-width: 600px) {
    .image-upload {
        width: 85vw;
    }

    .input-container input[type="file"] {
        margin: 0;
        padding: 18%;
    }

    .image-upload p {
        font-size: 11px;
    }

    .image-input-text {
        top: 35%;
    }

    .input-container {
        padding: 6px;
    }

    .upload-text {
        margin: 10px;
        padding: 30px;
    }
}

.prompt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% 0;
}

.prompt-container label {
    margin-right: 8px;
}

.prompt-container input {
    padding: 10px 12px;
    border-radius: 8px;
    border: 1.4px solid gray;
    color: #333333;
    width: 55%;
}